import { Routes, Route } from 'react-router-dom';
import SurveyFormPanel from './components/panels/SurveyFormPanel';
import ThankYouModal from './components/elements/ThankYouModal';

import './style.scss';

export default function App() {
  return (
    <Routes>
      <Route path="/">
        <Route path="/survey" element={<SurveyFormPanel />}>
          <Route path="thank-you" element={<ThankYouModal status="success" />} />
          <Route path="error" element={<ThankYouModal status="error" />} />
        </Route>
      </Route>
    </Routes>
  );
}
