import {
  ModalDialog,
  Container,
  Row,
  Col,
} from '@edx/paragon';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import './thank-you-modal.scss';

import { ReactComponent as SuccessIcon } from '../../../assets/assignment_turned.svg';
import { ReactComponent as WarningIcon } from '../../../assets/warning.svg';

const statusAccessories = {
  success: {
    title: 'Thank You!',
    des: `Thank you for taking the time to complete our survey. Your feedback will help us bring the best learning pathways to everyone in the AI community.\n(You can close this window now)`,
    icon: (<SuccessIcon className="custom-success-svg-style" />),
  },
  error: {
    title: 'Oops!',
    des: 'Seems something went wrong.',
    icon: (<WarningIcon className="custom-warning-svg-style" />),
  },
};

const ThankYouModal = ({
  status = '',
}) => {
  const statusAccessory = statusAccessories[status];
  const navigate = useNavigate();
  const handleClose = () => {
    if (status === 'error') {
      navigate(-1);
    }
  };

  return (
    <ModalDialog
      isOpen
      hasCloseButton={false}
      onClose={handleClose}
      title="default title"
    >
      <Container fluid>
        <Row className="justify-content-center">
          <Col className="mt-5 mb-4">
            <ModalDialog.Title className="text-center custom-h2-style">
              {statusAccessory.title}
            </ModalDialog.Title>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={6} sm={4} className="mb-4">
            {statusAccessory.icon}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-center px-5 mt-2 mb-6 custom-des-style">
            {statusAccessory.des}
          </Col>
        </Row>
      </Container>
    </ModalDialog>
  );
};

ThankYouModal.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ThankYouModal;
