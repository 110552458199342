import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroupContextProvider } from './FormGroupContext';

const FormGroup = ({
  children,
  controlId,
  isInvalid,
  isValid,
  size,
  as,
  ...props
}) => React.createElement(as, {
  ...props,
  className: classNames('pgn__form-group', props.className),
}, (
  <FormGroupContextProvider
    controlId={controlId}
    isInvalid={isInvalid}
    isValid={isValid}
    size={size}
  >
    {children}
  </FormGroupContextProvider>
));

const SIZE_CHOICES = ['sm', 'lg'];

FormGroup.propTypes = {
  /** Specifies contents of the component. */
  children: PropTypes.node.isRequired,
  /** Specifies class name to append to the base element. */
  className: PropTypes.string,
  /** Specifies base element for the component. */
  as: PropTypes.elementType,
  /** Specifies id to use in the group, it will be used as `htmlFor` in `FormLabel` and as `id` in input components.
   *  Will be autogenerated if none is supplied. */
  controlId: PropTypes.string,
  /** Specifies whether to display components in invalid state, this affects styling. */
  isInvalid: PropTypes.bool,
  /** Specifies whether to display components in valid state, this affects styling. */
  isValid: PropTypes.bool,
  /** Specifies size for the component. */
  size: PropTypes.oneOf(SIZE_CHOICES),
};

FormGroup.defaultProps = {
  as: 'div',
  className: undefined,
  controlId: undefined,
  isInvalid: false,
  isValid: false,
  size: undefined,
};

export default FormGroup;
