const myFetch = async (url, options) => {

  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_PRODUCTION_MODE === 'staging') {
      url = process.env.STAGING_APIDOMAIN + url;
    } else if (process.env.REACT_APP_PRODUCTION_MODE === 'production') {
      url = process.env.PRODUCTION_APIDOMAIN + url;
    }
  }

  const response = await fetch(url, options);
  if (response.status >= 400) {
    throw new Error(response);
  }

  const attachment = RegExp('attachment').test(response.headers.get('content-disposition'));
  let body;
  if (attachment) {
    window.location.assign(url);
  } else {
    body = response;
  }

  return { success: body };
};

const generateOptions = (options = {}) => {
  const intergratedOptions = {
    ...options,
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
      ...options.headers,
    },
    method: options.method,
    ...(options.method !== 'GET' ? { body: JSON.stringify(options.body) } : {}),
  };
  return intergratedOptions;
};

export const get = (url, options) => myFetch(url, generateOptions({ ...options, method: 'GET' }));
export const remove = (url, body, options) => myFetch(url, generateOptions({ ...options, body, method: 'DELETE' }));
export const post = (url, body, options) => myFetch(url, generateOptions({ ...options, body, method: 'POST' }));
export const put = (url, body, options) => myFetch(url, generateOptions({ ...options, body, method: 'PUT' }));
export const patch = (url, body, options) => myFetch(url, generateOptions({ ...options, body, method: 'PATCH' }));

export default myFetch;
