export const expOptions = [
  {
    level: 1,
    levelDes: 'This is my first course in AI',
  },
  {
    level: 2,
    levelDes: 'I have taken other AI courses, but have not worked on an AI system outside of course work',
  },
  {
    level: 3,
    levelDes: 'I have some experience (<3 years) building AI systems for personal projects',
  },
  {
    level: 4,
    levelDes: 'I have some experience (<3 years) building AI systems for work-related projects',
  },
  {
    level: 5,
    levelDes: 'I have 3+ years of experience building AI systems for work-related projects',
  },
];

export const fieldsTable = {
  lid: { payloadFieldName: 'lid', isRequired: true },
  formFirstName: { payloadFieldName: 'first_name', isRequired: true },
  formLastName: { payloadFieldName: 'last_name', isRequired: true },
  formEmail: { payloadFieldName: 'email', isRequired: true },
  formCourse: { payloadFieldName: 'course_name', isRequired: true },
  formExp: { payloadFieldName: 'level_of_exp', isRequired: true },
  formIsFirstTime: { payloadFieldName: 'is_first_time_learner', isRequired: true },
  formAdditlClass: { payloadFieldName: 'additional_courses', isRequired: false },
  formNewsSubsValue: { payloadFieldName: 'is_the_batch_subscriber', isRequired: false },
};

export const radioBtnValuesMap = {
  yes: true,
  no: false,
};

export const additlClassCharLimit = 80;
